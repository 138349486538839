<template>
    <div>
        <div class="toolBar mBottom10">
            <el-cascader v-show="global.isAdmin" :options="global.orgList" :show-all-levels="false"
                         v-model="global.orgId"
                         :props="global.orgTreeProps" @change='orgChange' size="small"></el-cascader>
            <el-button type="primary" size='small' plain style="margin-left: 20px" @click="addOrgDep">新增</el-button>
            <el-button plain size='small' class='fr' @click="refresh">刷新</el-button>
        </div>


        <el-row :gutter='10'>
            <el-col :md='6'>
                <el-card class="box-card orgItem">
                    <div slot="header">
                        <div class="institutional">部门名称</div>
                    </div>
                    <div class="leftTree">
                        <el-tree :data="depData" node-key="id" default-expand-all :expand-on-click-node="false"
                                 highlight-current
                                 check-strictly
                                 :check-strictly=true
                                 @node-click="handleNodeClick"
                                 :props="defaultProps">
                            <div class="custom-tree-node"
                                 style=' flex: 1;display: flex;align-items: center;justify-content: space-between;'
                                 slot-scope="{ node, data }">
                                <span>{{ node.label }}</span>
                                <el-button type="text" size="mini" class='hkDanger' @click="deleteOrgDep(node, data)">
                                    删除
                                </el-button>
                            </div>
                        </el-tree>
                    </div>

                </el-card>
            </el-col>

            <el-col :md='18'>
                <el-card class="box-card orgItem">
                    <div slot="header">
                        <span>部门详情</span>
                    </div>
                    <el-form ref="departmentForm" :rules="departmentFormRules" :model="departmentForm"
                             label-width="100px" size='small' style='padding-right: 120px;'>
                        <el-form-item label="父部门名称：" size='small'>
                            <el-cascader v-model="departmentForm.sonId" :props="selectProps" :options="depOptions"
                                         filterable
                                         style="width: 100%"></el-cascader>
                        </el-form-item>
                        <el-form-item label="部门名称：" prop="depName">
                            <el-input v-model="departmentForm.depName" size='small' clearable></el-input>
                        </el-form-item>
                        <el-form-item label="部门序号：" prop="sort">
                            <el-input type='number' v-model="departmentForm.sort" size='small' clearable></el-input>
                        </el-form-item>
                        <el-form-item style="float: right;margin-top: 20px">
                            <el-button size='small' @click="reset">重置</el-button>
                            <el-button v-show="!this.clickStatus" type="primary" size='small' @click="onsubmit">保存
                            </el-button>
                            <el-button v-show="this.clickStatus" type="primary" size='small' @click="update">修改
                            </el-button>
                        </el-form-item>
                    </el-form>
                </el-card>
            </el-col>


        </el-row>
    </div>
</template>

<script>
    class depForm {
        constructor() {
            this.orgId = null;
            this.parentId = null;
            this.sonId = [];
            this.sort = null;
            this.depName = null;
        }

        reset() {
            this.constructor();
        }
    }

    export default {
        data() {
            return {
                defaultProps: {children: 'children', label: 'depName', id: "depId"},
                selectProps: {children: 'children', label: 'depName', value: "depId", checkStrictly: true},
                value: null,
                deps: null,
                clickStatus: false,
                depOptions: [],
                depData: [],
                departmentForm: new depForm(),
                departmentFormRules: {
                    depName: [{required: true, message: '部门名称是必须的', trigger: 'blur'}],
                    sort: [{required: true, message: '部门序号是必须的', trigger: 'blur'}],
                },
            }
        },
        computed: {
        },
        methods: {
            resetForm(formName) {
                if (this.$refs[formName] !== undefined) {
                    this.$refs[formName].resetFields();
                }
            },
            refresh() {
                this.departmentForm.reset();
                this.load();
            },
            addOrgDep() {
                this.clickStatus = false;
                this.departmentForm.reset();
            },
            orgChange(val) {
                this.clickStatus = false;
                this.resetForm('departmentForm');
                this.departmentForm.reset();
                if (val && val.length) {
                    localStorage.setItem('orgId',JSON.stringify(val));
                    this.load()
                }
            },
            handleNodeClick(data) {
                this.clickStatus = true;
                this.resetForm('departmentForm');
                Object.assign(this.departmentForm, data);
                if (data.parentId) {
                    this.departmentForm.sonId = data.parentId
                }
            },
            reset() {
                this.departmentForm.reset();
            },
            onsubmit() {
                this.$refs['departmentForm'].validate((valid) => {
                    if (valid) {//表单验证通过
                        this.addOrgDeparment();
                    } else {
                        console.log('error submit!!');
                        return false;
                    }
                });
            },
            update() {
                this.$refs['departmentForm'].validate((valid) => {
                    if (valid) {//表单验证通过
                        this.updateOrgDeparment();
                    } else {
                        console.log('error submit!!');
                        return false;
                    }
                });
            },
            async updateOrgDeparment() {
                let paramObj = {
                    depName: this.departmentForm.depName,
                    id: this.departmentForm.depId,
                    orgId: this.organizationId,
                    parentId: this.departmentForm.parentId,
                    sort: this.departmentForm.sort,
                };
                let res = await this.$http.post('/sys/updOrgDep', paramObj);
                if (res.state) {
                    this.$message({message: '修改成功', type: 'success'});
                }
                this.departmentForm.reset();
                this.resetForm('departmentForm');
                this.load();
            },
            async addOrgDeparment() {
                let orgId = this.global.orgId[this.global.orgId.length-1];
                this.departmentForm.orgId = orgId;
                if (this.departmentForm.sonId == []) {
                    this.departmentForm.parentId = 0;
                } else {
                    this.departmentForm.parentId = this.departmentForm.sonId.pop() || 0;
                }
                let res = await this.$http.post('/sys/addOrgDep', this.departmentForm);
                if (res.state) {
                    this.$message({message: '成功', type: 'success'});
                }
                this.departmentForm.reset();
                this.resetForm('departmentForm');
                this.load();
            },
            async load() {
                this.clickStatus = false;
                let orgId = this.global.orgId[this.global.orgId.length-1];
                let param = {orgId: orgId};
                let res = await this.$http.post('/sys/orgDepTree', param);
                if (res.data) {
                    this.depData = res.data;
                    this.depOptions = res.data;
                } else {
                    this.depData = [];
                    this.depOptions = [];
                }
            },
            deleteOrgDep(node, data) {
                this.$confirm('是否删除该部门, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.delOrgDep(data.depId);
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消删除'
                    });
                });
            },
            async delOrgDep(id) {
                let param = {id: id};
                let res = await this.$http.post('/sys/delOrgDep', param);
                if (res.state) {
                    this.$message({message: '删除成功', type: 'success'});
                }
                this.departmentForm.reset();
                this.resetForm('departmentForm');
                this.load();
            },
            init() {
                if (this.global.orgId.length) {
                    this.load();
                }else {
                    setTimeout(() => {
                        this.init()
                    }, 200)
                }
            },
        },
        created() {
            this.init()
        },
    }
</script>

<style>
    .orgItem {
        height: calc(100vh - 190px);
    }

    .institutional {
        text-align: center;
    }
</style>